import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import classes from "./Otpsend.module.css";
import { SelectButton } from "primereact/selectbutton";
// import logo from "../Images/logo.png";
import logo from "../Images/gamevista.png";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Loading from "./Loading";

const ENDPOINT_URL = "https://ksg.intech-mena.com/MSG/v1.1/API/GetAggScript";

export const Otpsend = () => {
  const options = ["en", "ar"];
  const [code, setCode] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [language, setLanguage] = useState(options[0]);
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const timestamp = Date.now();

  //const [scriptContent, setScriptContent] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const mobileNumber = location.state ? location.state.mobileNumber : "";
  const evinaRequestId = location.state ? location.state.evinaRequestId : "";
  const operator = location.state ? location.state.operator : "";
  const [antiFrauduniqid, setAntiFrauduniqid] = useState("");

  useEffect(() => {
    async function getScript() {
      setLoading(true);

      try {
        let operatorId = operator;
        if (operator === "mobily") {
          operatorId = "16";
        }
        if (operator === "zain") {
          operatorId = "8";
        }
        console.log("request id in otp--- ", evinaRequestId);
        const getScriptURL = `${ENDPOINT_URL}?applicationId=197&countryId=207&requestId=${evinaRequestId}&TimeStamp%20=${timestamp}&channelid=99933518&ai=70&operatorid=${operatorId}`;
        // const getScriptURL = `${ENDPOINT_URL}?applicationId=193&countryId=207&requestId=${evinaRequestId}`;
        console.log("script url--- ", evinaRequestId);
        const response = await fetch(getScriptURL);

        if (!response.ok) {
          throw new Error(
            `Failed to fetch script: ${response.status} ${response.statusText}`
          );
        }

        const scriptContent = await response.json();
        console.log("response----- " + scriptContent["AntiFrauduniqid"]);
        setAntiFrauduniqid(scriptContent["AntiFrauduniqid"]);

        console.log("response " + scriptContent[100]);

        if (scriptContent) {
          let top_head = document.getElementsByTagName("head")[0];
          let anti_script = document.createElement("script");

          anti_script.innerHTML = scriptContent[100];
          top_head.insertBefore(anti_script, top_head.firstChild);

          var event = new Event("DCBProtectRun");
          // console.log(event);
          document.dispatchEvent(event);
          document.addEventListener("gateway-load", (event) => {
            //Enable form submission
            setScriptLoaded(true);
            console.log(event, "EVENT LOADED");
          });
        }
      } catch (error) {
        console.error("Error fetching script", error);
      } finally {
        setLoading(false);
      }
    }

    getScript();
  }, []);

  const handleContinue = (e) => {
    e.preventDefault();
    // let serviceName = "QuizBox";
    let applicationId = "197";
    // if (code && operator == "zain") {
    //   setLoader(true);
    //   axios
    //     .post("http://5.189.169.12:9795/zain/api/validate", {
    //       mobileNumber,
    //       code,
    //       language,
    //       evinaRequestId,
    //       operator,
    //       antiFrauduniqid,
    //       // serviceName,
    //       applicationId,
    //     })
    //     //  .post("http://localhost:8686/otp", {
    //     //   mobileNumber, code, language, evinaRequestId,operator,antiFrauduniqid,serviceName
    //     // })
    //     .then((response) => {
    //       console.log("Data sent successfully", response.data);
    //       setLoader(false);

    //       if (response.data.response === "Success") {
    //         window.location.href = "https://gamevistaportal.gameninja.in/";
    //       } else if (response.data.response === "Invalid") {
    //         if (language === "en") {
    //           setNotificationMessage("Invalid Otp!");
    //         } else {
    //           setNotificationMessage("كلمة المرور غير صالحة!");
    //         }
    //         setIsSubmitted(true);
    //       } else {
    //         if (language === "en") {
    //           setNotificationMessage("Something Went Wrong!");
    //         } else {
    //           setNotificationMessage("هناك خطأ ما!");
    //         }
    //         setIsSubmitted(true);
    //       }
    //     })
    //     .catch((error) => {
    //       setLoader(false);
    //       console.error("Error sending data to backend", error);
    //     });
    //   return;
    // }
    if (code) {
      setLoader(true);
      axios
        .post("https://www.backend.quizboxdemo.visiontrek.in/otp", {
          mobileNumber,
          code,
          language,
          evinaRequestId,
          operator,
          antiFrauduniqid,
          // serviceName,
          applicationId,
        })
        //  .post("http://localhost:8686/otp", {
        //   mobileNumber, code, language, evinaRequestId,operator,antiFrauduniqid,serviceName
        // })
        .then((response) => {
          console.log("Data sent successfully", response.data);
          setLoader(false);

          if (response.data.response === "Success") {
            // window.open("https://quizbox.gamewins.co.za/", "_blank");
            window.location.href = "https://gamevistaportal.gameninja.in/";
          } else if (response.data.response === "Invalid") {
            if (language === "en") {
              setNotificationMessage("Invalid Otp!");
            } else {
              setNotificationMessage("كلمة المرور غير صالحة!");
            }
            setIsSubmitted(true);
          } else {
            if (language === "en") {
              setNotificationMessage("Something Went Wrong!");
            } else {
              setNotificationMessage("هناك خطأ ما!");
            }
            setIsSubmitted(true);
          }
        })
        .catch((error) => {
          setLoader(false);
          console.error("Error sending data to backend", error);
        });
    } else {
      setShowNotification(true);
    }
  };

  function resetState() {
    setIsSubmitted(false);
  }

  return (
    <>
      <div className={classes.main}>
        <div className={classes.header}>
          <div className="card flex justify-content-center">
            <SelectButton
              value={language}
              onChange={(e) => setLanguage(e.value)}
              options={options}
            />
          </div>
        </div>

        <div className={classes.container}>
          <div className={classes.sub_container}>
            <div className={classes.center_form}>
              <form
                className={classes.otp_container}
                // onSubmit={handleContinue}
              >
                <div className={classes.logo_container}>
                  <div className={classes.logo_header}>
                    <img src={logo} alt="Logo" className={classes.logo} />
                  </div>
                </div>
                <p className={classes.title}>
                  {language === "en"
                    ? "Welcome to Our OTP Page"
                    : "مرحبًا بك في صفحة OTP الخاصة بنا"}
                </p>

                {scriptLoaded && !loading && (
                  <>
                    {isSubmitted ? (
                      <div className={classes.message_container}>
                        {/* <p className={classes.message}>
                          {language === "en"
                            ? "Something went wrong or the OTP is Invalid."
                            : "حدث خطأ أو الرمز OTP غير صحيح."}
                        </p> */}
                        <p className={classes.message}>{notificationMessage}</p>
                        <Button
                          label="Go Back"
                          icon="pi pi-arrow-left"
                          raised
                          onClick={resetState}
                        />
                      </div>
                    ) : (
                      <div className={classes.flex_gap}>
                        <div className={classes.input_container}>
                          <InputText
                            keyfilter="int"
                            placeholder={
                              language === "en"
                                ? "Confirm OTP"
                                : "تأكيد رمز OTP"
                            }
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                            style={{ width: "100%" }}
                          />
                        </div>

                        {showNotification && (
                          <div className={classes.message_container}>
                            <p className={classes.message}>
                              {language === "en"
                                ? "Please confirm the OTP."
                                : "يرجى تأكيد رمز OTP."}
                            </p>
                          </div>
                        )}

                        <div className={classes.input_container}>
                          <Button
                            label={language === "en" ? "Confirm" : "تأكيد"}
                            onClick={handleContinue}
                            id="evina_ctabutton"
                            raised
                            size="small"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {loader && <Loading />}

                {(!scriptLoaded || loading) && (
                  <div className={classes.input_container}>
                    <Loader loading={true} />
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
