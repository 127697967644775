
import React from 'react';
import { RingLoader } from 'react-spinners';

const Loader = ({ loading }) => {
  return (
    <div className="loader">
      <RingLoader size={60} color="#36D7B7" loading={loading} />
    </div>
  );
};

export default Loader;
