import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Landing } from '../Component/Landing';
import { Otpsend } from '../Component/Otpsend';

export const Routing = () => {
  return (
    <>
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Landing/>} exact={true}></Route>
            <Route path="/otp" element={<Otpsend/>} exact={true}></Route>
            <Route path="*" element={<Navigate to="/" />} exact={true}></Route>
        </Routes>
        </BrowserRouter>
    </>
  )
}
