import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { uuidv4 } from "./utils";
// import logo from "../Images/logo.png";
import logo from "../Images/gamevista.png";
import classes from "./Landing.module.css";
import { SelectButton } from "primereact/selectbutton";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import Loading from "./Loading";

export const Landing = () => {
  const options = ["en", "ar"];
  const [mobileNumber, setMobileNumber] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  //   const [language, setLanguage] = useState("en");
  const [language, setLanguage] = useState(options[0]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  // const [operator, setOperator] = useState("");
  const [extRef, setExtRef] = useState(null);
  const [loading, setLoading] = useState(false);

  // const operators = [
  //   {
  //     name: "Zain",
  //     value: "zain",
  //   },
  //   {
  //     name: "Mobily",
  //     value: "mobily",
  //   },
  // ];

  const operators = [
    {
      name: "Mobily",
      value: "mobily",
    },
  ];

  const [operator, setOperator] = useState(operators[0]?.value);

  const navigate = useNavigate();

  const handleContinue = (e) => {
    e.preventDefault();
    //const evinaRequestId = uuidv4();
    // let serviceName = "QuizBox";
    let applicationId = "197";

    //const evinaRequestId = uuidv4();
    let evinaRequestId;
    if (extRef === null) {
      evinaRequestId = uuidv4();
    } else {
      evinaRequestId = extRef;
    }

    if (!operator) {
      setNotificationMessage("Operator is required");
      setShowNotification(true);
      return;
    }

    // if (mobileNumber && operator == "zain") {
    //   setLoading(true);
    //   axios
    //     .post("http://5.189.169.12:9795/zain/api/requestpin", {
    //       mobileNumber,
    //       language,
    //       evinaRequestId,
    //       operator,
    //       // serviceName,
    //       applicationId,
    //     })
    //     .then((response) => {
    //       console.log("Data sent successfully", response.data);
    //       setLoading(false);

    //       if (response.data.response === "Success") {
    //         navigate("/otp", {
    //           state: { mobileNumber, evinaRequestId, operator },
    //         });
    //       } else if (response.data.response == "4") {
    //         window.location.href = "https://gamevistaportal.gameninja.in/";
    //       } else {
    //         console.log("Something went wrong!");
    //         setNotificationMessage(response.data.response);
    //         console.log(
    //           "After setting notification message:",
    //           notificationMessage
    //         );

    //         setIsSubmitted(true);
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);

    //       console.error("Error sending data to backend", error);
    //     });
    //   return;
    // }

    if (mobileNumber) {
      setLoading(true);
      axios
        .post("https://www.backend.quizboxdemo.visiontrek.in/ani", {
          mobileNumber,
          language,
          evinaRequestId,
          operator,
          // serviceName,
          applicationId,
        })
        // .post("http://localhost:8686/ani", { mobileNumber, language, evinaRequestId, operator, serviceName})
        .then((response) => {
          console.log("Data sent successfully", response.data);
          setLoading(false);

          if (response.data.response === "Success") {
            navigate("/otp", {
              state: { mobileNumber, evinaRequestId, operator },
            });
          } else if (response.data.response == "4") {
            window.location.href = "https://gamevistaportal.gameninja.in/";
          } else {
            console.log("Something went wrong!");
            setNotificationMessage(response.data.response);
            console.log(
              "After setting notification message:",
              notificationMessage
            );

            setIsSubmitted(true);
          }
        })
        .catch((error) => {
          setLoading(false);

          console.error("Error sending data to backend", error);
        });
    } else {
      setShowNotification(true);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ext_ref = urlParams.get("ext_ref");
    setExtRef(ext_ref);
    console.log(ext_ref, "msp");
    return () => {
      // Reset the state when the component unmounts
      setIsSubmitted(false);
      setShowNotification(false);
    };
  }, []);

  function resetState() {
    setIsSubmitted(false);
  }

  return (
    <>
      <div className={classes.main}>
        <div className={classes.header}>
          <div className="card flex justify-content-center">
            <SelectButton
              value={language}
              onChange={(e) => setLanguage(e.value)}
              options={options}
            />
          </div>
        </div>

        <div className={classes.container}>
          <div className={classes.sub_container}>
            <div className={classes.center_form}>
              <form
                className={classes.subscription_container}
                onSubmit={handleContinue}
              >
                <div className={classes.logo_container}>
                  <div className={classes.logo_header}>
                    <img src={logo} alt="Logo" className={classes.logo} />
                  </div>
                </div>

                {isSubmitted ? (
                  <div className={classes.message_container}>
                    <p className={classes.message}>{notificationMessage}</p>
                    <Button
                      label="Go Back"
                      icon="pi pi-arrow-left"
                      raised
                      onClick={resetState}
                    />
                  </div>
                ) : (
                  <>
                    <div className={classes.input_container}>
                      <p className={classes.text}>
                        {language === "en"
                          ? "Enter your mobile number "
                          : "أدخل رقم هاتفك المحمول"}
                      </p>

                      <IconField iconPosition="left" style={{ width: "100%" }}>
                        <InputIcon className="pi pi-mobile"> </InputIcon>
                        <InputText
                          keyfilter="int"
                          placeholder={
                            language === "en" ? "Mobile Number" : "رقم الجوال"
                          }
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          required
                          style={{ width: "100%" }}
                        />
                      </IconField>
                    </div>

                    <div className={classes.input_container}>
                      <Dropdown
                        value={operator}
                        onChange={(e) => setOperator(e.target.value)}
                        options={operators}
                        optionLabel="name"
                        placeholder={
                          language === "en" ? "Select Operator" : "حدد المشغل"
                        }
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div className={classes.input_container}>
                      <Button
                        label={language === "en" ? "Subscribe" : "إشترك"}
                        type="submit"
                        raised
                      />
                    </div>

                    {showNotification && (
                      <div className={classes.message_container}>
                        <p className={classes.message}>
                          {language === "en"
                            ? "Please Select the Operator"
                            : "الرجاء تحديد المشغل"}
                        </p>
                      </div>
                    )}
                  </>
                )}
                {loading && <Loading />}
              </form>

              <div
                className={`${
                  language === "en"
                    ? classes.terms_section
                    : classes.terms_section_right
                }`}
              >
                <h2 className={classes.title}>
                  {language === "en"
                    ? "Terms and Conditions"
                    : "الشروط و الاحكام"}
                </h2>
                <ul
                  className={`${
                    language === "en"
                      ? classes.list_section
                      : classes.list_section_right
                  }`}
                >
                  <li>
                    {language === "en"
                      ? "This service is available for Mobily customers for 2.3 SAR renewed daily (including VAT). To cancel your subscription, please send U 78 to 690001."
                      : "هذه الخدمة متوفرة لعملاء موبايلي مقابل ٢.٣ ريال، تتجدد يوميًا (شامل ضريبة القيمة المضافة). لإلغاء اشتراكك، الرجاء إرسال غ ٧٨ إلى ٦٩٠٠٠١."}
                  </li>
                  <li>
                    {language === "en"
                      ? "The Tax amount was collected to prepaid customers upon charging."
                      : "تم تحصيل مبلغ الضريبة لعملاء مسبق الدفع عند عملية شحن الرصيد."}
                  </li>
                  <li>
                    {language === "en"
                      ? "Subscribers of this service enjoy access to unlimited content."
                      : "يستمتع مشتركو هذه الخدمة بالوصول إلى محتوى غير محدود."}
                  </li>
                  <li>
                    {language === "en"
                      ? "To use this service, you must be 18+ years old or have received permission from your parents or a person authorized to pay your bill."
                      : "لاستخدام هذه الخدمة، يجب أن يكون عمرك 18 عامًا أو أكثر، أو أن تكون قد تلقيت إذنًا من والديك أو من الشخص المفوض لدفع فاتورتك."}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
