import React from 'react'
import classes from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={classes.loader_center}>
      <span className={classes.loader}></span>
    </div>
  )
}

export default Loading